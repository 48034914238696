<template>
	<page-header-wrapper>
		<template slot="extra">
		    <a-button type="primary" @click="add">新增</a-button>
		</template>
		<a-card :bordered="false">
			<div class="table-page-search-wrapper">
				<a-form layout="inline">
					<a-row :gutter="48">
						<!-- <a-col :md="8" :sm="24">
							<a-form-item label="角色ID">
								<a-input placeholder="请输入" />
							</a-form-item>
						</a-col>
						<a-col :md="8" :sm="24">
							<a-form-item label="状态">
								<a-select placeholder="请选择" default-value="0">
									<a-select-option value="0">全部</a-select-option>
									<a-select-option value="1">关闭</a-select-option>
									<a-select-option value="2">运行中</a-select-option>
								</a-select>
							</a-form-item>
						</a-col>
						<a-col :md="8" :sm="24">
							<span class="table-page-search-submitButtons">
								<a-button type="primary">查询</a-button>
								<a-button style="margin-left: 8px">重置</a-button>
							</span>
						</a-col> -->
					</a-row>
				</a-form>
			</div>
			
			<a-table :columns="columns" :data-source="listData" :rowKey="(record) => record.id">
				<span slot="serial" slot-scope="text, record, index">
					{{ index+1 }}
				</span>
				<span slot="module_key" slot-scope="module_key, record">
					<span v-if="module_key">{{module_key}}</span>
					<span v-else>--</span>
				</span>
				<span slot="status" slot-scope="text">
					--
				</span>
				<span slot="action" slot-scope="text, record">
					<a @click="editMenu(record)">编辑</a>
					<a-divider type="vertical" />
					<a href="javascript:;" @click="delMenu(record)">删除</a>
					<!-- <a-dropdown>
						<a class="ant-dropdown-link">
							更多
							<a-icon type="down" />
						</a>
						<a-menu slot="overlay">
							<a-menu-item>
								<a href="javascript:;">详情</a>
							</a-menu-item>
							<a-menu-item>
								<a href="javascript:;">禁用</a>
							</a-menu-item>
							<a-menu-item>
								<a href="javascript:;">删除</a>
							</a-menu-item>
						</a-menu>
					</a-dropdown> -->
				</span>
			</a-table>

			<a-modal title="操作" :width="520" v-model="visible" @ok="handleOk" class="modal_foot_center">
				<a-form :form="form" labelAlign="left">
					<!-- <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="唯一识别码" hasFeedback validateStatus="success">
						<a-input placeholder="唯一识别码" v-model="mdl.id" id="no" disabled="disabled" />
					</a-form-item> -->
					<a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="权限名称">
						<a-input placeholder="请输入权限名称" v-model="formData.name" id="permission_name" />
					</a-form-item>
					<a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="权限唯一键">
						<a-input placeholder="请输入权限唯一键" v-model="formData.pid" id="permission_id" />
					</a-form-item>
					<a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="父菜单">
						<a-select v-model="formData.fid" placeholder="请选择上级菜单">
							<a-select-option :value="item.id" v-for="(item,index) in menuList" :key="index">{{item.name}}</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="菜单类型">
						<a-radio-group name="radioGroup" v-model="formData.type">
							<a-radio :value="1">菜单</a-radio>
							<a-radio :value="3">URL</a-radio>
							<a-radio :value="2">按钮</a-radio>
						</a-radio-group>
					</a-form-item>
					<a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="key">
						<a-input placeholder="请输入key" v-model="formData.key" id="" />
					</a-form-item>
					<a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="排序">
						<a-input-number id="inputNumber" v-model="formData.sort" :min="0" :max="999" style="width:100%" />
					</a-form-item>
					<!-- <a-divider /> -->
					<!-- <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="按钮权限" hasFeedback>
						<a-select style="width:100%" mode="multiple" v-model="formData.actionList" :allowClear="true">
							<a-select-option v-for="(action, index) in permissionList" :key="index" :value="action.value">{{ action.label }}</a-select-option>
						</a-select>
					</a-form-item> -->
				</a-form>
			</a-modal>
		</a-card>
	</page-header-wrapper>
</template>

<script>
	import { STable } from '@/components'
	import {
		allMenuList,
		addMenu,
		editMenu,
		delMenu,
		allMenuLists
	} from '@/api/system'
	export default {
		name: 'TableList',
		components: {
			STable
		},
		data() {
			return {
				form:this.$form.createForm(this),
				menuList:[
					
				],
				description: '',
				visible: false,
				labelCol: {
					xs: {
						span: 24
					},
					sm: {
						span: 4
					}
				},
				wrapperCol: {
					xs: {
						span: 24
					},
					sm: {
						span: 20
					}
				},
				mdl:{},
				formData: {
					id:'',
					name:'',
					pid:'',
					fid:'0',
					type:1,
					key:'',
					sort:'',
				},

				// 高级搜索 展开/关闭
				advanced: false,
				// 查询参数
				queryParam: {},
				// 表头
				columns: [
					{
						title: '序号',
						scopedSlots: {
							customRender: 'serial'
						}
					},
					{
						title: '权限名称',
						dataIndex: 'name'
					},
					{
						title:'path',
						dataIndex:'page_content'
					},
					{
						title: 'key',
						dataIndex: 'module_key',
						scopedSlots: {
							customRender: 'module_key'
						}
					},
					// {
					// 	title: '状态',
					// 	dataIndex: 'status',
					// 	scopedSlots: {
					// 		customRender: 'status'
					// 	}
					// },
					{
						title: '操作',
						width: '150px',
						dataIndex: 'action',
						scopedSlots: {
							customRender: 'action'
						}
					}
				],
				listData:[],
				// 向后端拉取可以用的操作列表
				permissionList: null,
				// 加载数据方法 必须为 Promise 对象
				loadData: parameter => {
				
				},
				selectedRowKeys: [],
				selectedRows: []
			}
		},
		filters: {
			statusFilter(status) {
				const statusMap = {
					1: '正常',
					2: '禁用'
				}
				return statusMap[status]
			}
		},
		created() {
			this.getAllMenuList()
			this.getList()
		},
		methods: {
			add(){   //新增
				this.formData.id = ''
				this.getAllMenuList()
				this.visible = true
			},
			delMenu(data){
				let params = {
					id:data.id
				}
				let that = this
				this.$confirm({
					content:'确认删除吗?',
					onCancel(){
						
					},
					onOk(){
						delMenu(params).then(res =>{
							if(res.code==200){
								that.$message.success(res.msg)
								that.getAllMenuList()
								that.getList()
							}
						}).catch(res =>{
							
						})
					}
				})
				
			},
			getList(){  //列表
				let params = {
					
				}
				allMenuLists(params).then(res =>{
					if(res.code==200){
						this.listData = res.data
					}
				}).catch(res =>{
					
				})
			},
			getAllMenuList(){
				let params = {
					
				}
				allMenuList(params).then(res =>{
					if(res.code==200){
						this.menuList = [{id:'0',name:'一级菜单'}].concat(res.data)
					}
				}).catch(res =>{
					
				})
			},
			editMenu(data) {
				//console.log('data',data)
				this.formData.id = data.id
				this.formData.name = data.name
				this.formData.type = Number(data.type)
				this.formData.key = data.module_key,
				this.formData.pid = data.page_content
				this.formData.fid = data.fid
				this.formData.sort = data.sort
				this.visible = true
			},
			handleOk() {
				//console.log('formData',this.formData)
				let params = {
					name:this.formData.name.replace(/\|\_\_+/g,''),
					page_content:this.formData.pid,
					fid:this.formData.fid,
					type:this.formData.type,
					module_key:this.formData.key,
					id:this.formData.id,
					sort:this.formData.sort
				}
				if(!this.formData.name){
					this.$message.error('请输入权限名称')
					return false
				}else if(!this.formData.pid){
					this.$message.error('请输入权限唯一键')
					return false
				}
				if(this.formData.id){
					editMenu(params).then(res =>{
					if(res.code==200){
						this.$message.success(res.msg)
						this.getList()
						this.getAllMenuList()
						this.resetForm()
						this.visible = false
					}
				}).catch(res =>{
					
				})
				}else{
					addMenu(params).then(res =>{
						if(res.code==200){
							this.$message.success(res.msg)
							this.getList()
							this.visible = false
						}
					}).catch(res =>{
						
					})
				}
			},
			resetForm(){
				this.formData.id = ''
				this.formData.name = ''
				this.formData.type = 1
				this.formData.key = ''
				this.formData.pid = ''
				this.formData.fid = 0
				this.formData.sort = 0
			},
			onChange(selectedRowKeys, selectedRows) {
				this.selectedRowKeys = selectedRowKeys
				this.selectedRows = selectedRows
			},
			toggleAdvanced() {
				this.advanced = !this.advanced
			}
		},
		watch: {
			
		}
	}
</script>
